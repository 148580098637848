<template>
  <gf-base>
    <gf-card :tab="tabs" :active-tab="activeTab" @tab-click="tabClick">
      <template #toolbar>
        <gf-button type="secondary" @click="back">
          <template slot="icon">
            <span class="svg-icon svg-icon-sx">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <polygon points="0 0 24 0 24 24 0 24"/>
                  <path d="M4.7071045,12.7071045 C4.3165802,13.0976288 3.68341522,13.0976288 3.29289093,12.7071045 C2.90236664,12.3165802 2.90236664,11.6834152 3.29289093,11.2928909 L9.29289093,5.29289093 C9.67146987,4.914312 10.2810563,4.90106637 10.6757223,5.26284357 L16.6757223,10.7628436 C17.0828413,11.136036 17.1103443,11.7686034 16.7371519,12.1757223 C16.3639594,12.5828413 15.7313921,12.6103443 15.3242731,12.2371519 L10.0300735,7.38413553 L4.7071045,12.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(10.000001, 8.999997) scale(-1, -1) rotate(90.000000) translate(-10.000001, -8.999997) "/>
                  <path d="M20,8 C20.5522847,8 21,8.44771525 21,9 C21,9.55228475 20.5522847,10 20,10 L13.5,10 C12.9477153,10 12.5,10.4477153 12.5,11 L12.5,21.0415946 C12.5,21.5938793 12.0522847,22.0415946 11.5,22.0415946 C10.9477153,22.0415946 10.5,21.5938793 10.5,21.0415946 L10.5,11 C10.5,9.34314575 11.8431458,8 13.5,8 L20,8 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(15.750000, 15.020797) scale(-1, 1) translate(-15.750000, -15.020797) "/>
                </g>
              </svg>
            </span>
          </template>
          Back
        </gf-button>
      </template>
      <router-view :active.sync="activeTab"></router-view>
    </gf-card>
  </gf-base>
</template>

<script>
import PriceListService from '@/services/v1/PriceList'

export default {
  data () {
    return {
      activeTab: 1,
      tabs: [
        { id: 1, label: 'Details' }
      ],

      priceList: {
        name: ''
      }
    }
  },
  methods: {
    back () {
      this.$router.back()
    },
    tabClick (tab) {
      switch (tab) {
        case 1:
          this.$router.push({ name: 'pricelist-details' })
          break
        default:
          break
      }
    },
    async getPriceList () {
      try {
        const plService = new PriceListService(this.$route.params.id)
        const response = await plService.get()

        this.priceList.name = response.data.name
      } catch (error) {
        this.systemError()
      }
    }
  },
  created () {
    this.plService = new PriceListService()
  },
  async mounted () {
    await this.getPriceList()

    this.$store.dispatch(this.$SET_BREADCRUMB, [
      { title: 'Inventory', route: '' },
      { title: 'Price Lists' },
      { title: this.priceLists.name }
    ])
  }
}
</script>
